import uuid from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import { navigate, graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Page from '../layout/Page';
import Container from '../layout/Container';
import Hero from '../components/hero/Hero';
import Post from '../components/post/Post';
import Cta from '../components/cta/Cta';
import calculateSpacing from '../utils/calculate-spacing';

const Publications = ({ data, location, width }) => {
  const { prismicPublications } = data;
  if (!prismicPublications) {
    if (typeof window !== 'undefined') {
      navigate('/');
    }
    console.error('Page has not been translated.');
    return null;
  }

  const { data: prismicData } = prismicPublications;
  const {
    page_title,
    page_description,
    page_keywords,
    page_image,
    hero_title,
    hero_subtitle,
    hero_button,
    hero_image,
    post_button,
    posts,
    cta_title,
    cta_subtitle,
    cta_button
  } = prismicData;

  const spacing = calculateSpacing(isWidthDown, width);

  return (
    <Page
      seo={{
        page_title,
        page_description,
        page_keywords,
        page_image,
        pathname: location.pathname
      }}
    >
      <Container size={50} isCenter>
        <Hero
          title={hero_title}
          subtitle={hero_subtitle}
          image={hero_image}
          button={hero_button[0]}
        />
      </Container>

      <Container>
        <Grid container item direction="row" justify="center" spacing={spacing}>
          {posts &&
            posts.map(({ post: postNode }, index) => {
              const { document } = postNode;
              const { data: postData, uid } = document;

              return (
                <Post
                  post={{ ...postData, uid }}
                  isFirst={index === 0}
                  post_button={post_button}
                  key={uuid.v4()}
                />
              );
            })}
        </Grid>
      </Container>

      <Container
        size={50}
        background="url(/images/footer_background.png) bottom/cover no-repeat"
        style={{ marginTop: '-15%' }}
      />

      <Container background="#1976d2">
        <Cta title={cta_title} subtitle={cta_subtitle} button={cta_button[0]} />
      </Container>
    </Page>
  );
};

Publications.propTypes = {
  data: PropTypes.shape({
    prismicPublications: PropTypes.shape({
      data: PropTypes.shape().isRequired
    }).isRequired
  }).isRequired,
  width: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default withWidth()(Publications);

export const query = graphql`
  query PublicationsQuery($locale: String!) {
    prismicPublications: prismicPublications(lang: { eq: $locale }) {
      lang
      data {
        hero_title
        hero_image {
          alt
          copyright
          url
        }
        hero_button {
          title
          to
        }
        cta_title
        cta_subtitle
        cta_button {
          title
          to
        }
        post_button
        page_title
        page_description
        page_keywords
        page_image {
          alt
          copyright
          url
        }
        posts {
          post {
            document {
              ... on PrismicPost {
                uid
                data {
                  post_type
                  title
                  date(formatString: "MMMM Do, YYYY")
                  content {
                    html
                    text
                  }
                  page_title
                  page_description
                  page_keywords
                  page_image {
                    alt
                    copyright
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
