import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import TextTruncate from 'react-text-truncate';

import Link from '../link/Link';

const styles = theme => ({
  post: {
    [theme.breakpoints.only('xs')]: {},
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 8)
    }
  },
  textDecorationNone: {
    '& > *': {
      textDecoration: 'none'
    }
  }
});

const Post = ({ classes, post, post_button, isFirst }) => (
  <Grid item xs={12} sm={10} md={10}>
    <Card raised className={classes.post}>
      <CardContent>
        <Typography variant="h6" component="h3" gutterBottom>
          {post.date}
        </Typography>
        <Typography variant="h4" component="h3" gutterBottom>
          {post.title}
        </Typography>
        <Typography variant="body1" component="div" align="justify">
          <TextTruncate
            line={isFirst ? 6 : 2}
            truncateText="…"
            text={post.content.text}
          />
        </Typography>
      </CardContent>
      <CardActions className={classes.textDecorationNone}>
        <Link to={`/${post.post_type.toLowerCase()}/${post.uid}`}>
          <Button variant="contained" color="primary">
            {post_button}
          </Button>
        </Link>
      </CardActions>
    </Card>
  </Grid>
);

Post.propTypes = {
  classes: PropTypes.shape({
    post: PropTypes.string.isRequired
  }).isRequired,
  post: PropTypes.shape({
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.shape({
      html: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  post_button: PropTypes.string.isRequired,
  isFirst: PropTypes.bool
};

Post.defaultProps = {
  isFirst: false
};

export default withStyles(styles)(Post);
